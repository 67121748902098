<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    id="page-login"
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col">
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title pb-4">
                  <h4 class="mb-4">Generate License Key</h4>
                  <p>
                    Hi there, this program is to be used solely by the staff of
                    Express POS to generate license keys for customers. Logging
                    session
                    {{ date }}.
                    <router-link to="/install">Return Back.</router-link>
                  </p>
                  <vs-tabs>
                    <vs-tab label="Parameters">
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 w-full">
                          <span class="text-sm"> Start Date</span>
                          <datepicker
                            v-model="startDate"
                            placeholder="Start Date"
                            @input="clearLicense"
                          />
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                          <span class="text-sm"> Expiry Date</span>
                          <datepicker
                            :disabled-dates="disabledDates"
                            v-model="expiryDate"
                          />
                        </div>
                        <div class="vx-col md:w-1/3 w-full">
                          <vs-select
                            v-validate="'required'"
                            v-model="period"
                            class="select-large"
                            label="Period"
                            name="period"
                            @input="updateExpiry"
                          >
                            <vs-select-item
                              v-for="(item, index) in periods"
                              :key="index"
                              :value="item.data"
                              :text="item.label"
                              class="w-full"
                            />
                          </vs-select>
                        </div>
                        <div class="vx-col w-full mt-5">
                          <vs-textarea
                            v-model="machineID"
                            v-validate="'required'"
                            :rows="9"
                            label="Installation ID"
                            class="w-full"
                            name="installation_id"
                            @input="clearLicense"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first('installation_id')
                          }}</span>
                        </div>
                      </div>
                    </vs-tab>
                    <vs-tab label="License Key">
                      <vs-textarea
                        v-model="license"
                        :rows="10"
                        disabled="true"
                        label="License Key"
                        class="w-full"
                      />
                    </vs-tab>
                    <vs-tab label="Metadata">
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 w-full">
                          <vs-input
                            v-validate="'required'"
                            v-model="business_name"
                            label="Business Name"
                            class="w-full"
                            name="business_name"
                          />
                          <span class="text-danger">{{
                            errors.first('business_name')
                          }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                          <vs-input
                            v-validate="'required|numeric|max:10|min:10'"
                            v-model="main_phone"
                            label="Main Phone"
                            class="w-full"
                            name="main_phone"
                          />
                          <span class="text-danger">{{
                            errors.first('main_phone')
                          }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full mt-5">
                          <vs-input
                            v-validate="'required'"
                            v-model="postal_address"
                            label="Postal Address"
                            class="w-full"
                            name="address"
                          />
                          <span class="text-danger">{{
                            errors.first('address')
                          }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full mt-5">
                          <vs-input
                            v-validate="'required'"
                            v-model="postal_city"
                            label="Postal City"
                            class="w-full"
                            name="city"
                          />
                          <span class="text-danger">{{
                            errors.first('city')
                          }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full mt-5">
                          <vs-select
                            v-validate="'required'"
                            v-model="country"
                            class="w-full select-large"
                            label="Country"
                            name="country"
                            @input="updateCurrency"
                          >
                            <vs-select-item
                              v-for="(item, index) in countryOptions"
                              :key="index"
                              :value="item"
                              :text="item.countryName"
                              class="w-full"
                            />
                          </vs-select>
                          <span class="text-danger">{{
                            errors.first('county')
                          }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 w-full mt-5">
                          <vs-input
                            v-validate="'required'"
                            v-model="currency"
                            label="Currency"
                            disabled="true"
                            class="w-full"
                            name="currency"
                          />
                          <span class="text-danger">{{
                            errors.first('currency')
                          }}</span>
                        </div>
                        <vs-divider>Tax</vs-divider>
                        <div class="vx-col md:w-1/2 w-full">
                          <vs-input
                            v-model="tax_pin"
                            label="Tax PIN"
                            class="w-full"
                            name="pin"
                          />
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                          <vs-input
                            v-model="vat_reg"
                            label="VAT Reg"
                            class="w-full"
                            name="vat_reg"
                          />
                        </div>
                      </div>
                    </vs-tab>
                  </vs-tabs>
                  <div class="vx-row justify-between">
                    <vs-button
                      v-if="!loading"
                      :disabled="!formValid"
                      class="flex p-3 m-2 flex-wrap ml-4 center"
                      @click="enterPass = true"
                      >Generate License</vs-button
                    >
                    <div v-show="license != null">
                      <vs-button
                        v-clipboard:copy="license"
                        v-clipboard:success="copy"
                        v-clipboard:error="error"
                        class="flex flex-wrap p-3 m-2 ml-4 mr-4 center bg-success"
                        >Copy License</vs-button
                      >
                    </div>
                    <vs-prompt
                      :is-valid="passwordValid"
                      :active.sync="enterPass"
                      title="Authenticate"
                      @cancel="close"
                      @accept="generate"
                      @close="close"
                    >
                      <div>
                        <span>Enter Security Code MIMD</span>
                        <vs-input
                          v-model="password"
                          type="password"
                          placeholder="Code"
                          vs-placeholder="Code"
                          class="mt-3 w-full"
                        />
                      </div>
                    </vs-prompt>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import crypto from 'crypto';
import Datepicker from 'vuejs-datepicker';
import countries from './countries.json';

const dict = {
  custom: {
    business_name: {
      required: 'A business name is required',
    },
    main_phone: {
      required: 'A phone number is required',
      numeric: 'Phone numbers may only be numeric',
      max: 'A valid 10 digit phone number is required',
      min: 'A valid 10 digit phone number is required',
    },
    address: {
      required: 'A postal address is required',
    },
    city: {
      required: 'A valid postal city is required',
    },
    country: {
      required: 'Select a country',
    },
    currency: {
      required: 'A valid currency code is required',
    },
  },
};

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      business_name: '',
      main_phone: '',
      postal_address: '',
      postal_city: '',
      country: {},
      c: '',
      tax_pin: '',
      vat_reg: '',
      countryOptions: countries,

      enterPass: false,
      password: '',
      loading: false,
      pk: '',
      ppk: '',
      machineID: '',
      other: '',
      license: null,
      date: new Date(Date.now()),
      startDate: new Date(),
      expiryDate: null,
      disabledDates: {
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDay() + 10,
        ),
      },
      period: null,
      periods: [
        {
          label: '1 Week',
          data: 7,
        },
        {
          label: '2 Weeks',
          data: 14,
        },
        {
          label: '1 Month',
          data: 30,
        },
        {
          label: '3 Months',
          data: 90,
        },
        {
          label: '6 Months',
          data: 180,
        },
        {
          label: '1 Year',
          data: 360,
        },
        {
          label: '2 Years',
          data: 720,
        },
      ],
    };
  },
  computed: {
    currency: {
      get() {
        return this.c;
      },
      set(d) {
        this.c = d;
      },
    },
    formValid() {
      return (
        !this.errors.any() && this.machineID != '' && this.expiryDate != null
      );
    },
    passwordValid() {
      return this.password.length > 2;
    },
  },
  created() {
    this.machineID = this.$route.params.id;
    setInterval(this.dateStamp, 1000);
    this.ppk =
      '-----BEGIN PUBLIC KEY-----\n' +
      'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGC7ihHUgBC5NdYRF/xxGKGdhn2W\n' +
      '2IXRhaKYZEJvr1s6t+tQMN9yYvhr4j3eZk1zJlMgXOXFr0mOncA3UnLVNQPwsitK\n' +
      'bjh2yWA99lNiW3fAWhlanX2RJPbVP/wuueI5URZXXaxkpH/b/RSswXvjpof+kjED\n' +
      'YRf6+GxCrzJyof/1AgMBAAE=\n' +
      '-----END PUBLIC KEY-----';
    this.pk =
      '-----BEGIN RSA PRIVATE KEY-----\n' +
      'MIICWgIBAAKBgGC7ihHUgBC5NdYRF/xxGKGdhn2W2IXRhaKYZEJvr1s6t+tQMN9y\n' +
      'Yvhr4j3eZk1zJlMgXOXFr0mOncA3UnLVNQPwsitKbjh2yWA99lNiW3fAWhlanX2R\n' +
      'JPbVP/wuueI5URZXXaxkpH/b/RSswXvjpof+kjEDYRf6+GxCrzJyof/1AgMBAAEC\n' +
      'gYBYrranIA2Ex3fu2F8IJdtyF4v8SbYaAPumFFYefmvilJOXHT6hLCpGHAoftGxP\n' +
      '5xikrw/b63+HTUOcvFtPQd5GrDCbFRpT+z36KZEBuBJUa1ib+yZFHJSUiLCV/cN0\n' +
      'oDOKllu82lmU3xg7u8QaHctS/lu4X7LwbIFJKnqbAKSYoQJBAKkzGPN2taBKX9tb\n' +
      '1vq03E0k+VCRBY8ATtfbmEhDebDq6VkiBM5SuUo9QtyRvVKGCoeVWvP51C1Z5uMi\n' +
      'Bd8ZIu0CQQCSW2HbAaWoozh51jQnleuBzx7w5XOnjUzgVUqaQKCQPPzeO7WJXWXq\n' +
      'QxuFNMSy1y0G6vJCrAMLzEQblJCEFAgpAkATI3F+EH5o3CDNmizN/OkyXK1KvKyp\n' +
      'STqh1ff6rI82QMwWRe/POrI0wIswkdedXwYP4ggAstCaNKIWLQIHAzvdAkA5ZTbh\n' +
      '8SprEN9kAFnhsEx0Uqp6pDEP4zzjuO8RwQh2qEQuTtIpdYQzM3AGdCQzUmkpDlhF\n' +
      'RznGlaub+U4XTdfJAkAm81x6wlEq7GIhkXvTVVxX75l9boZ8a/4XqnmLMJS27+rt\n' +
      'FRXD+GqYah2ecUexJDoBnMbTILpeM6yj3FCy2dqS\n' +
      '-----END RSA PRIVATE KEY-----';
  },
  methods: {
    updateCurrency() {
      this.currency = this.country.currencyCode;
    },
    dateStamp() {
      this.date = new Date(Date.now());
    },
    clearLicense() {
      this.license = null;
    },
    close() {
      this.password = '';
      this.$vs.notify({
        title: 'Error',
        text: 'License Generation Cancelled. You have not been authenticated.',
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
      });
    },
    copy() {
      this.$vs.notify({
        title: 'Success',
        text: 'License successfully copied to clipboard',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle',
      });
    },
    error() {
      this.$vs.notify({
        title: 'Error',
        text: 'Failed to copy license to clipboard',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle',
      });
    },
    addDays(date, days) {
      const c = new Date(Number(date));
      c.setDate(date.getDate() + days);
      return c;
    },
    updateExpiry() {
      this.clearLicense();
      this.expiryDate = this.addDays(this.startDate, this.period);
    },
    sign(k, data) {
      const sign = crypto.createSign('RSA-SHA256');
      sign.update(new Buffer.from(JSON.stringify(data)));
      var s = sign.sign(k, 'hex');
      return s;
    },
    encrypt(d) {
      const algo = 'aes-256-cbc';
      const sk = crypto.randomBytes(32);
      const iv = crypto.randomBytes(16);
      const cipher = crypto.createCipheriv(algo, Buffer.from(sk), iv);
      const en = Buffer.concat([cipher.update(d), cipher.final()]);
      return {
        sk: sk.toString('hex'),
        iv: iv.toString('hex'),
        d: en.toString('hex'),
      };
    },
    checkPassword() {
      var d = this.date;
      var p = `${d.getMinutes()}${d.getMonth()}${d.getDate()}`;
      if (this.password === p) {
        return true;
      }
      return false;
    },
    generate() {
      if (this.formValid) {
        if (this.checkPassword()) {
          this.clearLicense();
          var d = {
            machineID: this.machineID,
            start: this.startDate,
            expiry: this.expiryDate,
            metadata: {},
          };
          var s = this.sign(this.pk, d);
          var en = this.encrypt(
            btoa(
              JSON.stringify({
                signature: s,
                data: d,
              }),
            ),
          );
          this.license = `${en.sk}:${en.iv}:${en.d}`;
          this.$vs.notify({
            title: 'Cheers',
            text: 'License Key Generated Successfully',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          });
        } else {
          this.clearLicense();
          this.$vs.notify({
            title: 'Error',
            text: 'Invalid security code provided, cannot proceed with license generation',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Invalid data passed. Please check your entries and try again.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
